import React from 'react'
import styled from 'styled-components'
import { Link as InternalLink } from 'gatsby'
import { colors } from '../theme'

const ExternalLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  color: ${colors.primaryDark};

  &:visited {
    ${colors.primaryDark};
  }
`

const Link = styled(({ className, href, to, children, ...props }) =>
  href ? (
    <ExternalLink href={href} className={className}>
      {children}
    </ExternalLink>
  ) : (
    <InternalLink to={to} className={className}>
      {children}
    </InternalLink>
  )
)`
  display: inline;
`

export default Link
