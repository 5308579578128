import React from 'react'
import styled from 'styled-components'
import MetaHeader from '../../components/MetaHeader'
import Layout from '../../layouts'
import PageWrapper from '../../components/PageWrapper'
import PageIntro from '../../components/PageIntro'
import CohortApplicationForm from '../../components/CohortApplicationForm'
import TextBlock from '../../components/TextBlock'
import { graphql } from 'gatsby'
import { vars } from '../../components/theme'
import Button from '../../components/Button'
import Testimonials from '../../components/Testimonials'

const FormContainer = styled.div`
  margin: 0 0 1rem;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: ${vars.screenLarge}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
  }
`
const CTA = styled.div`
  margin: 3rem 0;
`
const CTAButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
`

const Section = styled.section`
  margin: 0;
`

const Paragraph = styled.div`
  margin-bottom: 30px;
`

const formSubsection = `Join our next collaborative cohort of 
powerhouse women around North America to develop your successful 
path to investing.   Please apply by September 23rd. `

const subtitle = 'Empowering women to shape the future of funding.'

const editedQuote =
  'Joining Angel Academy was my way to make a difference with the knowledge and network to start investing in early-stage ventures.'

const addHighlighting = html =>
  html.replace(/&lt;mark&gt;/g, '<mark>').replace(/&lt;\/mark&gt;/g, '</mark>')

const Applications = ({ data }) => {
  const {
    contentfulAngelAcademyPage: {
      pageIntro: {
        heading,
        image,
        metaTitle,
        metaSiteUrl,
        metaDescription,
        metaKeywords,
        metaTwitter,
      },
      body,
    },
    contentfulAngelAcademyQuotes: testimonialData,
  } = data

  return (
    <Layout>
      <MetaHeader
        title={metaTitle || heading}
        image={image?.file?.url}
        siteUrl={metaSiteUrl}
        description={metaDescription?.metaDescription}
        keywords={metaKeywords?.metaKeywords}
        twitter={metaTwitter}
      />
      <PageWrapper>
        <PageIntro
          title={heading}
          subtitle={subtitle}
          imgSrc={image?.file?.url}
          alt={image?.title}
        />
      </PageWrapper>

      <PageWrapper>
        <Container>
          <div>
            <Paragraph
              dangerouslySetInnerHTML={{
                __html: addHighlighting(body.childContentfulRichText.html),
              }}
            />
            <Testimonials
              data={[
                {
                  node: {
                    order: 1,
                    ...testimonialData,
                    quote: { quote: editedQuote },
                  },
                },
              ]}
            />
            <CTA>
              <TextBlock center>
                <CTAButton to="/angel-academy/how-it-works" outline>
                  Tell Me More
                </CTAButton>
              </TextBlock>
            </CTA>
          </div>
          <FormContainer id="form-application">
            <Section>
              <CohortApplicationForm
                tight
                title={'Join Angel Academy'}
                info={false}
                subsection={formSubsection}
                questions={false}
              />
            </Section>
          </FormContainer>
        </Container>
      </PageWrapper>
    </Layout>
  )
}

export default Applications

export const query = graphql`
  query {
    contentfulAngelAcademyPage(
      id: { eq: "49adaca3-322f-5915-98da-b31f457eef59" }
    ) {
      pageIntro {
        heading
        image {
          file {
            url
          }
        }
        metaTitle
        metaDescription {
          metaDescription
        }
        metaKeywords {
          metaKeywords
        }
        metaSiteUrl
        metaTwitter
      }
      body {
        childContentfulRichText {
          html
        }
      }
    }
    contentfulAngelAcademyQuotes(
      id: { eq: "520ec3c9-34ef-5c7b-8818-cd7779a89af0" }
    ) {
      id
      author
      title
      quote {
        quote
      }
      image {
        file {
          url
        }
      }
    }
  }
`
