import React from 'react'
import styled from 'styled-components'
import { vars } from '../components/theme'

const Wrapper = styled.div`
  margin: 4rem 0 1rem;
  text-align: ${props => (props.center ? 'center' : 'left')};
`
const Intro = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${vars.screenMedium}) {
    grid-gap: 1.5rem;
  }
`
const Image = styled.img`
  height: auto;
  width: auto;
  max-height: 18rem;
  max-width: 100%;
  margin: auto;
  flex: 1 1 auto;
  object-fit: contain;
  position: relative;
`
const Subtitle = styled.h3`
  margin: 0;
`

const PageIntro = ({ title, subtitle, imgSrc, alt = '', center }) => (
  <Wrapper center={center}>
    <h1>{title}</h1>
    {(subtitle || imgSrc) && (
      <Intro>
        {imgSrc && <Image src={imgSrc} alt={alt} />}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Intro>
    )}
  </Wrapper>
)

export default PageIntro
