import styled from 'styled-components'
import { colors, fonts } from '../../theme'

const SectionTitle = styled.h4`
  font-family: ${fonts.primary};
  color: ${colors.primaryText};
  padding-bottom: 0.25rem;
  margin-bottom: 0.75rem;
  border-bottom: 1px solid ${colors.borderLight};

  &:first-child {
    margin-top: 0;
  }
`

export default SectionTitle
