import React from 'react'
import styled from 'styled-components'

import { colors, vars } from './theme'
import { Row, Col } from './Grid'
import Link from './common/Link'
import { Input } from './common/form'
import Button from './Button'

const Heading = styled.h3`
  max-width: 40rem;
`

const Wrapper = styled.div`
  background: ${colors.white};
  border-radius: 1px;
  border: 1px solid ${colors.primary};
  padding: 1rem;
  margin-left: auto;
  position: relative;
  max-width: calc(100vw - 8vw);

  @media (min-width: 900px) and (max-width: 967px) {
    max-width: calc(100vw - 2rem);
  }

  @media (min-width: 968px) {
    max-width: calc((100vw + 916px) / 2);
    padding: ${props => (props.tight ? '1rem 2rem' : '3rem 4rem')};
  }

  a {
    color: ${colors.secondary};

    &:visited {
      color: ${colors.secondary};
    }

    &:hover,
    &:focus,
    &:active {
      color: ${colors.secondaryDark};
    }
  }
`
const InnerWrapper = styled.div`
  max-width: calc(84vw - 3rem);

  @media (min-width: ${vars.screenMedium}) {
    max-width: calc(900px - 4rem);
  }
`

const ContactForm = styled(({ className, onSubmit, children }) => (
  <form
    onSubmit={onSubmit}
    className={`${className} form`}
    name="cohortApplicationJan2019"
    method="POST"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
    action="/application-success">
    <input type="hidden" name="form-name" value="cohortApplicationJan2019" />
    <Input name="firstName" label="First Name" />
    <Input name="lastName" label="Last Name" />
    <Input name="email" label="Email" type="email" />
    <Input name="linkedinLink" label="LinkedIn Profile" type="url" optional />
    <Row smallGutter>
      <Col>
        <Input name="city" label="City" />
      </Col>
      <Col>
        <Input name="country" label="Country" />
      </Col>
    </Row>
    <Button primary submit>
      Submit
    </Button>
  </form>
))`
  button {
    margin-top: 0.75rem;
  }
`

export default ({
  title = 'Join the Female Funders Community',
  tight = false,
  info = true,
  subsection = 'Qualified candidates will be considered on a rolling basis.',
  questions = true,
}) => (
  <Wrapper tight={tight}>
    <InnerWrapper>
      <Row largeGutter>
        <Col minWidth>
          <Heading className="mt-0">{title}</Heading>

          <div>
            <p>{subsection}</p>
            {questions && (
              <p>
                <strong>Questions?</strong>
                <br />
                Email{' '}
                <a href="mailto:hello@femalefunders.com">
                  hello@femalefunders.com
                </a>
              </p>
            )}
            {info && (
              <p>
                <strong>
                  Are you an entrepreneur seeking early-stage investment?
                </strong>
                <br />
                Apply to pitch your startup at our Investor Roundtables{' '}
                <Link to="/founders-registration"> here</Link>.
              </p>
            )}
            <p />
          </div>
        </Col>
        <Col small minWidth>
          <ContactForm />
        </Col>
      </Row>
    </InnerWrapper>
  </Wrapper>
)
