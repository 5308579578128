import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { colors, vars } from '../components/theme'

const StyledButton = styled.button`
  display: inline-block;
  position: relative;
  margin-top: 0.375rem 0;
  padding: 0.25em 0.75em;
  font-weight: 600;
  font-size: ${props => (props.large ? '1.25rem' : '1rem')};
  letter-spacing: 0.04em;
  text-decoration: none;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  border: 1px solid ${colors.primary};
  border-radius: 3px;

  background: ${props => (props.outline ? colors.white : colors.primary)};
  color: ${props => (props.outline ? colors.primary : colors.white)};

  &:hover,
  &:focus {
    border: 1px solid ${colors.primaryDark};
    color: ${props => (props.outline ? colors.primary : colors.white)};
    background: ${props =>
      props.outline ? colors.lightGrey : colors.primaryDark};
  }
  &:visited {
    color: ${props => (props.outline ? colors.primary : colors.white)};
  }

  @media screen and (min-width: ${vars.screenSmall}) {
    padding: 0.75em 1.75em;
  }
`

const Button = ({ to, hashLink, href, children, submit, ...rest }) => (
  <>
    {to ? (
      // internal link, styled as a button
      <StyledButton as={Link} to={to} {...rest}>
        {children}
      </StyledButton>
    ) : hashLink ? (
      // anchor link, styled as a button
      <StyledButton as="a" href={hashLink} {...rest}>
        {children}
      </StyledButton>
    ) : href ? (
      // anchor link, styled as a button
      <StyledButton
        as="a"
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}>
        {children}
      </StyledButton>
    ) : (
      // regular button
      <StyledButton type={submit ? 'submit' : 'button'} {...rest}>
        {children}
      </StyledButton>
    )}
  </>
)

export default Button
