import React from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'
import { LabelText } from './base'

import Checkbox from './Checkbox'

const Label = styled(LabelText)`
  font-size: 0.875em;
  color: ${colors.grey};

  display: flex;
  flex-wrap: wrap;
  width: ${({ horizontal }) => (horizontal ? 'initial' : '100%')};
  margin: ${({ horizontal }) => (horizontal ? '0 0.5rem 0' : '0')};
  flex-direction: row;
  align-items: flex-start;

  margin-bottom: 0.25rem;
  padding: 0.75rem 0 0;

  &:last-of-type {
    margin-bottom: 1rem;
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ horizontal }) => (horizontal ? 'row' : 'column')};
  flex-wrap: wrap;

  align-items: baseline;
`

const Caption = styled.div.attrs({
  id: 'legalDefinitions',
})`
  line-height: 1.5;
  margin: 0.25rem 0;
  font-size: 0.85rem;
  color: ${colors.secondaryText};
  font-style: italic;
`

export default ({
  type,
  label,
  caption,
  name,
  options,
  optional,
  implied,
  horizontal,
  ...props
}) => (
  <>
    <LabelText optional={optional}>
      {label}
      {caption && <Caption>{caption}</Caption>}
      <Container horizontal={horizontal}>
        {options.map(option => (
          <Label key={option.value} horizontal={horizontal}>
            <Checkbox name={name} value={option.value} type={type} {...props} />
            {option.label} {option.children}
          </Label>
        ))}
      </Container>
    </LabelText>
  </>
)
