import styled from 'styled-components'
import { vars } from '../components/theme'

const GOLDEN_RATIO = 1.618

const Row = styled.div`
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  flex-wrap: wrap;
  position: relative;

  ${props => {
    if (props.smallGutter) {
      return `
        margin-left: -0.25rem;
        margin-right: -0.25rem;

        > div {
          margin-left: 0.25rem;
          margin-right: 0.25rem;
        }
        `
    } else if (props.largeGutter) {
      return `
        margin-left: -1rem;
        margin-right: -1rem;

        > div {
          margin-left: 1rem;
          margin-right: 1rem;
        }`
    } else {
      return `
        margin-left: -0.5rem;
        margin-right: -0.5rem;`
    }
  }};
`

const Col = styled.div`
  flex: ${GOLDEN_RATIO} 0 0;
  margin: 0 0.5rem;
  padding: 0;
  max-width: 100%;
  ${props => {
    return props.smallFull
      ? `
      flex: 1 0 100%;

      @media (min-width: ${vars.screenSmall}) {
        flex: 1.618 0 0;
      }
      `
      : `flex: 1.618 0 0;`
  }} ${props => {
    if (props.small) {
      return `
      flex: 1 0 100%;

      @media (min-width: ${vars.screenSmall}) {
        flex: 1 0 0;
      }`
    } else if (props.large) {
      return `flex-grow: ${GOLDEN_RATIO + 1} ;`
    } else if (props.single) {
      return `
      flex: 1 1 ${(GOLDEN_RATIO - 1) * 100}%;

      @media (min-width: ${vars.screenMedium}) {
        flex-grow: 0;
      }

      `
    } else {
      return `flex-grow: ${GOLDEN_RATIO};`
    }
  }};

  min-width: ${props =>
    props.minWidth
      ? props.minWidth === true
        ? '18rem'
        : props.minWidth
      : '8rem'};

  > img {
    width: 100%;
  }
`

export { Row, Col }
