import styled from 'styled-components'

const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  height: 1rem;
  min-width: 0;
  margin-right: 0.5rem;
`

export default Checkbox
