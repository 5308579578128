import React from 'react'
import { Input, OtherInput } from './Input'
import RadioCheck from './RadioCheck'
import Textarea from './Textarea'
import SectionTitle from './SectionTitle'
import FormSection from './FormSection'

const Checkbox = ({ ...props }) => <RadioCheck type="checkbox" {...props} />
const Radio = ({ ...props }) => <RadioCheck type="radio" {...props} />

export {
  Checkbox,
  Input,
  OtherInput,
  Radio,
  FormSection,
  SectionTitle,
  Textarea,
}
