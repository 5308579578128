import React from 'react'
import QuoteBlock from './common/QuoteBlock'

const sortByOrder = data => data.sort((a, b) => a.node.order - b.node.order)

const Testimonials = ({ data }) => (
  <>
    {sortByOrder(data).map(({ node: { id, author, title, quote, image } }) => (
      <QuoteBlock
        key={id}
        img={image?.file?.url}
        quote={quote.quote}
        author={author}
        title={title}
      />
    ))}
  </>
)

export default Testimonials
