import React from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'
import { styles, Label, LabelText } from './base'

const StyledTextarea = styled.textarea`
  ${styles};
  height: 4rem;
  border: 1px solid
    ${props => (props.dark ? colors.borderDark : colors.borderLight)};
`

export default ({ label, optional, ...props }) => (
  <Label>
    <LabelText optional={optional}>{label}</LabelText>
    <StyledTextarea required={!optional} {...props} />
  </Label>
)
