import React from 'react'
import styled from 'styled-components'
import { colors } from '../theme'

import blobMask from '../../images/svgs/mask-blob.svg'

const Wrapper = styled.div`
  margin: 0.5rem auto 2.5rem;
  max-width: 600px;
`

const Image = styled(({ className, src }) => (
  <div className={className}>
    <img src={src} alt="" />
  </div>
))`
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: ${props => (props.contain ? 'contain' : 'cover')};
    border-radius: 1rem;
    mask: ${props => (props.nomask ? 'none' : `url(${blobMask})`)};
    -webkit-mask-size: contain;
    -webkit-mask-repeat: no-repeat;
  }
`

const Source = styled.div`
  display: grid;
  grid-template-columns: 5rem 1fr;
  grid-gap: 1rem;
  align-items: center;
`

const Quote = styled.div`
  font-size: 1.125rem;
  line-height: 1.3;
  color: ${colors.black};
  margin-bottom: 0.75rem;
  position: relative;

  &:before {
    content: '“';
  }

  &:after {
    content: '”';
  }
`

const Author = styled.div`
  color: ${colors.black};
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  font-weight: 600;
`

const Title = styled.div`
  color: ${colors.grey};
  font-style: italic;
`
const QuoteBlock = ({
  img,
  author,
  title,
  quote,
  contain,
  nomask,
  children,
}) => (
  <Wrapper>
    <Quote>{quote ? quote : children}</Quote>
    <Source>
      <Image contain={contain} nomask={nomask} src={img} />
      <div>
        <Author>{author}</Author>
        <Title>{title}</Title>
      </div>
    </Source>
  </Wrapper>
)

export default QuoteBlock
