import React from 'react'
import styled from 'styled-components'
import { colors } from '../../theme'
import { styles, Label, LabelText } from './base'

const StyledInput = styled.input`
  ${styles};
  border: 1px solid
    ${props => (props.dark ? colors.borderDark : colors.borderLight)};
  height: 2.125rem;
  font-size: 0.875rem;

  &::placeholder {
    opacity: 0.6;
  }
`

const OtherInput = styled(StyledInput)`
  height: 1.25rem;
  width: 70%;
  margin: 0 0 0 0.25rem;
  padding: 0.25rem 0.25rem;
`
const Input = ({
  label,
  optional,
  type = 'text',
  name,
  placeholder,
  ...props
}) => (
  <Label {...props}>
    <LabelText optional={optional}>{label}</LabelText>
    <StyledInput
      name={name}
      required={!optional}
      type={type}
      placeholder={placeholder}
    />
  </Label>
)

export { Input, OtherInput }
