import styled from 'styled-components'
import { darken } from 'polished'
import { colors } from '../../theme'

const styles = `
  width: 100%;
  padding: 0.25rem 0.625rem;
  margin: 0.25rem 0 0.75rem;
  border-radius: 3px;
  color: ${colors.primaryText};
  background: rgba(255, 255, 255, 0.8);

  &::placeholder {
    color: ${props =>
      props.dark ? colors.primaryText : colors.primaryTextLight};
  }

  &:required {
    box-shadow: none;
  }
`

const Label = styled.label`
  text-align: left;
`

const LabelText = styled.label`
  color: ${darken(0.2, colors.grey)};
  font-size: 14px;

  ${props =>
    props.optional &&
    `
      &::after {
        content: ' – Optional';
        color: ${colors.borderLight};
        font-size: 0.9rem;
      }
    `};
`

export { styles, Label, LabelText }
