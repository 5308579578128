import React from 'react'
import styled from 'styled-components'

import SectionTitle from './SectionTitle'

const Section = ({ className, title, children }) => (
  <div className={className}>
    <SectionTitle>{title}</SectionTitle>
    {children}
  </div>
)

export default styled(Section)`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 0 1rem;
`
