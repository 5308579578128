import styled from 'styled-components'
import { vars } from '../components/theme'

const TextBlock = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: ${props => (props.center ? 'center' : 'left')};

  @media (min-width: ${vars.screenMedium}) {
    ${props => props.small && 'max-width: 35rem'};
  }
`
export default TextBlock
